// Colours
$primary: #491f69;
$primary-alpha10: rgba($primary, 0.1);
$primary-alpha15: rgba($primary, 0.15);
$primary-alpha20: rgba($primary, 0.2);
$primary-light: #faf3ff;
$primary-medium: #e9d9f5;

$secondary: #00c8a0;
$secondary-light: #96fbe7;

$white: #ffffff;
$grey-verylight: #f2f2f2;
$grey-light: #e5e5e5;
$grey-medium: #9d9d9d;
$grey-dark: #333;
$black: #000000;

$status-success: #18ed2d;
$status-warning: #f99033;
$status-danger: #e00000;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,400;0,700;1,300;1,400;1,700&display=swap');
$font-family: 'Poppins', sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// UI
$border-color: rgba($primary, 0.2);
$border-color: rgba($primary, 0.1);
$border-radius: 5px;

$status-online: $status-success;
$status-offline: $status-danger;

$box-shadow: 0px 0px 10px rgba($black, 0.25);
$box-shadow-light: 0px 0px 10px $primary-alpha15;
$box-shadow-heavy: 0px 4px 27px $primary-alpha15;

$spacing: 16px;
$spacing-sm: 8px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 64px;

$headerHeight: '80px';
$footerHeight: '60px';

$break-xl: 1440px;
$break-lg: 1170px;
$break-md: 768px;
$break-sm: 480px;

@import "./variables";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: $font-family;
  color: #000000;
  font-size: 14px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, div {
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #00C8A0 !important;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  ul,
  ol {
    padding-left: 16px;
  }
}

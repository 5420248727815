.ant-input-prefix {
  margin-right: 15px !important;
}

.ant-input {
  &:hover {
    border-color: #00c8a0;
  }
  &:focus {
    border-color: #00c8a0;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 200, 160, 0.2);
  }
}

.ant-collapse-header {
  display: inline-flex;
}

.tippy-box,
tippy-content {
  .ant-form-item-explain-error {
    margin-left: 60px !important;
  }
  background: #f6f6f6;
}

.tippy-content {
  color: black;
}

.ant-btn {
  &:hover,
  &:active,
  &:focus {
    color: #00c8a0;
    border: 1px solid #00c8a0;
  }
}

.ant-btn-primary {
  background: #00c8a0;
  border: 1px solid #00c8a0;

  font-size: 14px;
  font-weight: 700;

  &:hover,
  &:active,
  &:focus {
    color: white;
    background: #00b48f;
    border: 1px solid #00c8a0;
  }
}

.ant-btn-default {
  background: #ebebeb;
  border: 1px solid #ebebeb;

  border-radius: 5px;

  font-size: 14px;
  font-weight: 700;

  &:active {
    background: #ebebeb;
    border: 1px solid #ebebeb;
  }

  &:hover,
  &:focus {
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    color: black;
  }
}

.ant-form-item-explain-error {
  color: #ff4d4f;
  div {
    color: #ff4d4f;
  }
}

.ant-select-focused {
  .ant-select-selector {
    border-color: #00c8a0 !important;
    box-shadow: 0 0 0 2px rgba(0, 200, 160, 0.2) !important;
  }
}

.ant-select {
  .ant-select-selector {
    border: 1px solid #f3f3f3 !important;
  }
  &:hover {
    .ant-select-selector {
      border-color: #00c8a0 !important;
    }
  }

  .ant-select-selector {
    height: 40px !important;

    display: flex;
    align-items: center;
  }
}
.ant-select-multiple .ant-select-selector {
  min-height: 40px !important;
  height: auto !important;
}

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border-color: #00c8a0;
    }
  }
}

.ant-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border-color: #00c8a0;
    }
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #00c8a0;
    border-color: #00c8a0;
  }
  &::after {
    border-color: #00c8a0;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    display: flex;
    align-items: center;

    &:hover {
      background: none;
      > * {
        color: #00c8a0;
      }
    }

    border-bottom: 1px solid #ebebeb;
  }
  .ant-select-item-option-content {
    color: #737373;
    &:hover,
    &:active {
      color: #00c8a0;
    }
  }
  .ant-select-item-option-selected {
    background: #fff;

    .ant-select-item-option-content {
      color: #00c8a0;
    }
  }
}

.ant-picker {
  height: 40px;

  &:hover {
    border-color: #00c8a0;
  }

  input {
    border-color: transparent !important;
  }
}

.ant-picker-focused {
  border-color: #00c8a0;
  box-shadow: none;

  .ant-picker-input-active {
    input {
      border-color: transparent;
    }
  }

  .ant-picker-active-bar {
    background: #00c8a0;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-range-start {
    .ant-picker-cell-inner {
      background: #00c8a0;
    }
  }
  .ant-picker-cell-inner {
    &:before {
      border-color: #02ae8c !important;
    }
  }
}

.ant-spin-dot i {
  background-color: #00c8a0;
}

.ant-popover {
  .ant-btn {
    height: 30px;
    padding: 0 7px;
  }
}

.popup-content {
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px !important;
}

.dropdown-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}

// Select - removed border from the last item in the list
.rc-virtual-list-holder-inner {
  .ant-select-item {
    &:last-child {
      border-bottom: none;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

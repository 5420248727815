.is-invalid {
  border-color: #e00000 !important;
  box-shadow: none !important;

}

.invalid-feedback {
  color: #e00000;
  flex-grow: 1;
  margin-top: 3px !important;
}

html {
    font-family: system-ui, sans-serif;
    font-size: 1.25rem;
  }
  
  body {
    margin: 0;
  }
  
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  
  path {
    fill: #2a354d;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  